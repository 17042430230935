<template>
  <div class="m-order-pre">
	<van-overlay :show="showOverlay" @click="closeshowOverlay()">
	  <div class="goods-money-pay-content" @click.stop >
		  <div style="font-size:14px;color:#999999">確認訂單金額</div>
		  <div style="font-size:22px;margin:10px 0px;color:red">NT${{TotalMoney}}</div>
		  <div style="margin:10px 0px;">
			<div style="margin-bottom:20px;font-size:14px;color:#999999">請選擇訂單支付方式</div>
			<van-radio-group v-model="payType">
			  <van-radio name="2" style="margin-bottom:20px;">庫存貨物<span style="color: red;">剩餘({{moneyInfo.goodsMoney/100}})</span></van-radio>
			  <van-radio name="18">歷史積分<span style="color: red;">剩餘({{moneyInfo.transferMoney/100}})</span></van-radio>
			</van-radio-group>
		  </div>
		  <div>请输入支付密码</div> 
			<input
			  class="custom-input"
			  v-model="password"
			  type="password"
			/>  			 
		 <div style="display:flex;justify-content: space-around;margin-top:20px;align-items: center;">
		   <van-button type="primary" size="normal" text="确认支付" @click="isshowOverlay()" />
		   <!-- <van-button type="warning" size="small" text="忘记密码" @click="isshowOverlay()" />	 -->
	<!-- 	   <a href="#/resetPassword" style="color: #999;text-decoration: underline;font-size: 14px;">
				<span>
				  忘记密码
				</span>
		   </a> -->
		 </div>
	  </div>
	</van-overlay>	  
	  
    <div
      class="order-page"
      :style="
        '--aog-currency:\'' +
        SYSLANG.currency +
        '\'; --aog-totalprice:\'' +
        formatMoney(TotalMoney) +
        '\';'
      "
    >
      <router-link class="content address" to="/address?fromorder=1">
        <div v-if="addrinfo && addrinfo.createDate > 0" class="info">
          <span class="area"
            >{{ addrinfo.provinceName }} {{ addrinfo.cityName }} {{ addrinfo.districtName }}
             {{ addrinfo.detail }}</span
          >
          <!-- <span class="detail">{{ addrinfo.AddressDetail }}</span> -->
          <span class="phone"
            >{{ addrinfo.contact }} {{ addrinfo.phone }}</span
          >
        </div>
        <div v-else class="info">{{ PAGELANG.notaddrss }}</div>
      </router-link>
      <div class="content shopping-list">
        <div class="big-title">{{ PAGELANG.gouwuqingdan }}</div>
        <div class="order-goods-list">
          <template v-for="(goods, index) in ShippingList" :key="index">
            <div class="goods">
              <van-image
                width="100"
                height="100"
                radius="8"
                :src="goods.thumb"
                fit="cover"
              />
              <div class="info">
                <div class="pdt-name">
                  <span class="name">{{ goods.productName }}</span>
                </div>
                <div class="pdt-props">
                  <span>{{ goods.propertyValues }}</span>
                </div>
                <div class="pdt-props">
                  <span class="price">{{ goods.retailPrice/100 }}</span>
                  <span class="buy-count">×{{ goods.cartQuantity }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
		<div>
<!-- 			<van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
			<van-popup v-model:show="showShippingType" position="bottom">
			  <van-picker
			    name="CountryID"
			    :title="PAGELANG.選擇快遞"
			    :columns="countryList"
			    :columns-field-names="addressFieldName"
			    :loading="countryLoading"
			    v-model="CountryID"
			    @confirm="onConfirmCountry"
			    @cancel="showShippingType = false"
			  />
			</van-popup>
		</div>
		<div class="shopping-item">
		  <span class="title">快遞方式</span>
		 <!-- <span v-if="ShippingFee> 0" class="value price">{{ShippingName}}</span> -->
		  <span class="value price" @click="showPopup"><van-cell is-link @click="showPopup" style="color: #000000;">{{ShippingName}}</van-cell></span> 
		  
		</div>
		
		
		
        <div class="shopping-item">
          <span class="title">{{ PAGELANG.freight }}</span>
          <span v-if="ShippingFee> 0" class="value price">{{ShippingFee}}</span>
		  <span v-else class="value" @click="showPopup">{{ShippingName}}</span> 
        </div>
		
		
		<div class="shopping-item" v-if="isGiveStickers==1">
		  <span class="title">附贈圓貼紙</span>
		 <!-- <span v-if="ShippingFee> 0" class="value price">{{ShippingName}}</span> -->
		  <span>      
			  <el-switch
			  v-model="isShowGiveStickers"
			  size="large"
			  active-value=1
			  inactive-value=0
			  style="--el-switch-on-color: var(--van-card-price-color)"
			/>
		  </span> 

		</div>
		
        <div class="shopping-item">
          <span class="title">{{ PAGELANG.remark }}</span>
          <van-field
            v-model="order.buyerRemark"
            right-icon="aog iconfont icon-right-02"
            :placeholder="PAGELANG.remarkdesc"
            input-align="right"
            readonly
            class="value"
            @click="showRemark = true"
          />
        </div>
      </div>
      <van-submit-bar
        v-if="!showDelete"
        :decimal-length="0"
        :placeholder="true"
        :price="TotalMoney * 100"
        :currency="SYSLANG.currency"
        :button-text="PAGELANG.submit"
        :label="SYSLANG.amountto + ': '"
        button-color="linear-gradient(to right, #e4c994, #cdaa66)"
        @submit="onSubmit"
      >
      </van-submit-bar>
    </div>

    <van-popup v-model:show="showRemark" position="bottom">
      <div class="remark-popup">
        <div class="header">
          <span class="cancel" @click="showRemark = false">{{
            SYSLANG.quxiao
          }}</span>
          <span class="title">{{ PAGELANG.remark }}</span>
          <span
            class="submit"
            @click="
              order.buyerRemark = buyerRemark;
              showRemark = false;
            "
            >{{ SYSLANG.queding }}</span
          >
        </div>
        <van-field
          v-model="buyerRemark"
          rows="6"
          type="textarea"
          maxlength="200"
          :placeholder="PAGELANG.remarkdesc"
          show-word-limit
          class="remark-input"
        />
      </div>
    </van-popup>
	
	
	
  </div>

  <!-- <div
    class="pc-order-pre"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="order-content">
      <div class="big-title">{{ PAGELANG.收货地址 }}</div>
      <van-radio-group
        v-model="addrid"
        shape="shape"
        checked-color="#000"
        class="address-block"
        @change="changeAddress"
      >
        <ul class="address">
          <li v-if="addressList.length <= 0 && addrinfo && addrinfo.ID > 0">
            <van-radio :name="addrinfo.ID">
              {{ addrinfo.Country }} {{ addrinfo.Province }}
              {{ addrinfo.City }} {{ addrinfo.County }}
              {{ addrinfo.AddressDetail }}
              <span class="phone">
                {{ addrinfo.ReceiverName }} {{ addrinfo.MobilePhone }}
              </span>
              <span v-if="addrinfo.IsDefault == 1" class="set-default"
                >({{ ADDRLANG.default }})</span
              >
              <a
                v-else
                class="set-default"
                @click.stop="setDefault(addrinfo.ID)"
                >{{ ADDRLANG.设置为默认地址 }}</a
              >
            </van-radio>
            <span class="edit" @click="editAddress(addrinfo.ID)">{{
              ADDRLANG.编辑地址
            }}</span>
          </li>
          <template v-else>
            <li v-for="(addr, index) in addressList" :key="index">
              <van-radio :name="addr.ID">
                {{ addr.Country }} {{ addr.Province }} {{ addr.City }}
                {{ addr.County }} {{ addr.AddressDetail }}
                <span class="phone">
                  {{ addr.ReceiverName }} {{ addr.MobilePhone }}
                </span>
                <span v-if="addr.IsDefault == 1" class="set-default"
                  >({{ ADDRLANG.default }})</span
                >
                <a
                  v-else
                  class="set-default"
                  @click.stop="setDefault(addr.ID)"
                  >{{ ADDRLANG.设置为默认地址 }}</a
                >
              </van-radio>
              <span class="edit" @click="editAddress(addr.ID)">{{
                ADDRLANG.编辑地址
              }}</span>
            </li>
          </template>
        </ul>
      </van-radio-group>
      <div class="addr-btns">
        <el-button
          v-if="addressList.length <= 0 && addrcount > 1"
          color="#000"
          @click="getAllAddress"
          >{{ PAGELANG.使用其他地址 }}</el-button
        >
        <el-button v-else color="#000" @click="editAddress()">{{
          ADDRLANG.addbtn
        }}</el-button>
      </div>
    </div>

    <div class="order-content">
      <div class="big-title">
        {{ PAGELANG.gouwuqingdan }}
      </div>
      <div class="pc-cart">
        <ul class="my-cart">
          <li class="cart-item header">
            <div class="pdt-name">{{ CARTLANG.商品信息 }}</div>
            <div class="price">{{ CARTLANG.价格 }}</div>
            <div class="count">{{ CARTLANG.buycount }}</div>
            <div class="control">{{ CARTLANG.小计 }}</div>
          </li>
          <li
            v-for="(item, index) in ShippingList"
            :key="index"
            class="cart-item"
          >
            <van-image
              width="100"
              height="100"
              radius="0"
              :src="UPLOADBASE + item.PdtThumb"
              fit="cover"
              class="thumb"
            />
            <div class="pdt-name">
              <div class="title" @click="viewGoods(item.GoodsID)">
                {{ item.PdtName }}
              </div>
              <div class="props">{{ item.SelPropsText }}</div>
            </div>
            <div class="price">
              {{ item.RPriceProp }}
            </div>
            <div class="count">{{ item.BuyCount }}</div>
            <div class="control price">
              {{ item.TotalRPrice }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="order-content">
      <div class="big-title">
        {{ PAGELANG.remark }}
      </div>
      <el-input
        v-model="order.buyerRemark"
        :rows="2"
        type="textarea"
        :placeholder="PAGELANG.remarkdesc"
        resize="none"
        class="remark"
      />
    </div>

    <div class="order-content">
      <div class="pc-cart">
        <div class="cart-menu">
          <div class="control"></div>
          <div class="submit">
            <span class="freight">
              {{ PAGELANG.freight + ": " }}
              <label v-if="ShippingFee > 0" class="price">{{
                formatMoney(ShippingFee)
              }}</label>
              <label v-else>{{待確定}}</label>
            </span>
            {{ SYSLANG.amountto + ": "
            }}<label class="price">{{ formatMoney(TotalMoney) }}</label>
            <el-button
              size="large"
              color="#cdaa66"
              style="margin-left: 20px"
              @click="onSubmit"
              >{{ PAGELANG.submit }}</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <AddressEdit
      :AddrID="addressid"
      @editAddress="editAddress"
      @onRefresh="getAllAddress"
    />
  </div> -->
</template>

<script>
import { closeToast, showLoadingToast, showToast} from "vant";
import { getCookie } from "../util";
import md5 from 'md5'	
// import AddressEdit from "../components/AddressEdit.vue";
export default {
  // components: {
  //   AddressEdit,
  // },
  data() {
    return {
	  payType:2,
      addrinfo: {},
      CouponList: [],
      GoodsCount: 0,
      OrderCount: 0,
      OrderMoney: 0,
      ShippingList: [],
      TotalMoney: 0,
      gbuycounts: "",
      goodsids: "",
      ShippingFee: 0,
      order: {
        buyerRemark: "",
      },
      showRemark: false,
      addrcount: 100,
      addressList: [],
      addrid: 0,
      addressId:"",
	  skus:[],
	  isFirst:0,
	  ShippingName:"待確定",
	  showShippingType:false,
	  countryList:[],
	  addressFieldName: {
	    text: "name",
	    value: "shipmentId",
	    children: "children",
	  },
	  isShowGiveStickers:false,//商品是否有团贴纸属性
	  luckCharmOrderStatus:0,//不使用混选
	  value: '123',
	  showKeyboard:false,//展示密码输入框
	  payPassword:"",//Miami
	  showOverlay:false,
	  selectCoupon:[],//使用的优惠劵
	  orderCode:"",//订单号
	  resultant:0 ,//1688 商品數量倍數
	  
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	

    this.PAGELANG = this.LANG.pages.order;
    this.ADDRLANG = this.LANG.pages.address;
    this.CARTLANG = this.LANG.pages.cart;
    this.SYSLANG = this.LANG.system;
    this.UPLOADBASE = this.uploadbase;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });
    console.log('history.state',history.state)

	this.skus=JSON.parse(history.state.skus)
    this.luckCharmOrderStatus=history.state.luckCharmOrderStatus
	if(history.state.deductionSkus.length>0){
     this.deductionSkus=JSON.parse(history.state.deductionSkus)		
	}
	if(history.state.resultant){
	 this.resultant=JSON.parse(history.state.resultant)		
	}


    console.log('this.pagedata.addrinfo',this.pagedata.addrinfo)
	this.axios.get(this.actions.memberMoney).then((response) => {
	  closeToast();
	 let { code, message, data } = response.data;
	  if (code == "200") {
	    this.moneyInfo = data;
	  } else {
	    showToast({
	      message:message,
	      forbidClick: true,
	      onClose: () => {
	        if (code == 400) {
	          this.$router.replace({
	            path: "/login",
	          });
	        }
	      },
	    });
	  }
	}); 
	 if (this.pagedata.addrinfo) {
	   this.addrinfo = this.pagedata.addrinfo;
	   this.addressId=this.pagedata.addrinfo.addressId;
	   delete this.pagedata.addrinfo;
	   this.initTwo()
	 }else{
		this.init();
		 
	 }
	 	
    // if (!this.orderdata) {
    //   showToast({
    //     message: this.PAGELANG.notdata,
    //     forbidClick: true,
    //     onClose: () => {
    //       this.$router.back();
    //     },
    //   });
    //   return;
    // }

    //this.isfromcart = history.state.isfromcart;
	
	 // if(history.state.forward==undefined){
		//      this.init(); 
	 // }else{
		//  console.log('this.pagedata.addrinfo',this.pagedata.addrinfo)
		//  if (this.pagedata.addrinfo) {
		//    this.addrinfo = this.pagedata.addrinfo;
		//    this.addressId=this.pagedata.addrinfo.addressId;
		//    delete this.pagedata.addrinfo;
		//    this.getShippingFee();
		//    this.init()
		//  }
	 // }
	 
	 closeToast();
	 
	 
	 
  },
  methods: {
	  
	
	onInput(key) {
	      this.payPassword = (this.payPassword + key).slice(0, 6);
		  console.log('payPassword',this.payPassword)
	},
	onDelete() {
	      this.payPassword = this.payPassword.slice(0, this.payPassword.length - 1);
	},  
	  
	onConfirmCountry(val) {
		 console.log('val',val.selectedOptions[0].shipmentId);
		 this.showShippingType = false;
		 this.shipmentId=val.selectedOptions[0].shipmentId
		 this.axios
		   .post(this.actions.orderCalc, {
		           skus:this.skus,
		 		   addressId:this.addressId,
		 		   deductionSkus:this.deductionSkus,
		 		   luckCharmOrderStatus:this.luckCharmOrderStatus,
				   shipmentId: this.shipmentId,
		 		   type:"take",
				   resultant:this.resultant
		   })
		   .then((response) => {
		     closeToast();
		     let { code, message } = response.data;
		     if (code == "200") {
		 		 console.log('response',response)
		 	     //this.addrinfo=response.data.data.addressModel
		 		   this.ShippingFee=response.data.data.totalFee/100
		 		   this.ShippingList=response.data.data.skus[0].list
		 		   this.TotalMoney=(response.data.data.totalProductMoney/100+response.data.data.totalFee/100)
		 		   this.ShippingName=val.selectedOptions[0].name
				   this.isShowGiveStickers=response.data.data.isGiveStickers
			
				   //let deductibleNumber=0 //赠品数量
				  // if(this.deductionSkus.length>0){
				  // 						let money=0//赠品金额
				  // 						 this.discountList=response.data.data.memberBrandCodeModels
				  // 						 this.discountList.map((item)=>{
				  // 								   money=money+(item.deductibleAmount/100)
				  // 						 })
				  // 						this.TotalMoney=this.TotalMoney-money   
				  // }
				   
				   
		     } else {
		       showToast(
		         code != 200 ? message : message
		       );
		     }
		 })
		 
	},  
	showPopup(){
		this.showShippingType = true;
	},  
    formatMoney(money) {
	  return money;
      // if (!money) return "0.00";
      // let integer = Math.floor(money).toString();
      // let thousands = integer
      //   .split("")
      //   .reverse()
      //   .join("")
      //   .replace(/(\d{3})/g, "$1.")
      //   .replace(/,$/, "");

      // if (thousands.substr(-1, 1) == ".") {
      //   thousands = thousands.substring(0, thousands.length - 1);
      // }

      // return thousands.split("").reverse().join("");
    },
    init() {
		console.log('this.addressId',this.addressId)
		console.log('this.isFirst',this.isFirst)
		if(this.isFirst==1){
			return
		}
		this.axios
		  .post(this.actions.orderCalc, {
		           skus:this.skus,
				   addressId:this.addressId,
				   deductionSkus:this.deductionSkus,
				   luckCharmOrderStatus:this.luckCharmOrderStatus,
				   type:"take",
				   resultant:this.resultant
		  })
		  .then((response) => {
		    closeToast();
		    let { code, message } = response.data;
		    if (code == "200") {
				 console.log('response',response)
			     //this.addrinfo=response.data.data.addressModel
				   this.ShippingFee=response.data.data.totalFee/100
				   this.ShippingList=response.data.data.skus[0].list
				   this.TotalMoney=(response.data.data.totalProductMoney/100+response.data.data.totalFee/100)
				   this.isFirst=1
				   this.isGiveStickers=response.data.data.isGiveStickers
				   //用戶有默認地址
				   if(response.data.data.addressModel && response.data.data.addressModel.createDate>0){
					   this.addrinfo=response.data.data.addressModel
					   this.getShippingFee()
				   } 
				  //  if(this.deductionSkus.length>0){
						//  let money=0//赠品金额
						//  let number=0 //赠品数量 (10的倍数)
						//  this.skus.map((item)=>{
						// 	 number=number+(item.cartQuantity/10)
						//  })
						//  this.discountList=response.data.data.memberBrandCodeModels
						//  this.discountList.map((item,index)=>{
						// 	       if(index<number){
						// 			  money=money+(item.deductibleAmount/100) 
						// 			  this.selectCoupon.push(item)
						// 		   }
					  
						//  })
						// this.TotalMoney=this.TotalMoney-money   
				  //  }
				   
				   
				   
		    } else {
		      showToast(
		        code != 200 ? message : message
		      );
		    }
		})
		
		
		/*
      let url = this.isfromcart
        ? this.actions.order_orderc
        : this.actions.order_order;
      this.axios
        .post(url, {
          orderdata: this.orderdata,
        })
        .then(({ data }) => {
          closeToast();
          let { code, msg, order } = data;

          if (code == "SUCCESS") {
            this.CouponList = order.CouponList;
            this.GoodsCount = order.GoodsCount;
            this.OrderCount = order.OrderCount;
            this.OrderMoney = order.OrderMoney;
            this.ShippingList = order.ShippingList;
            this.TotalMoney = order.TotalMoney;
            this.gbuycounts = order.gbuycounts;
            this.goodsids = order.goodsids;
            this.addrinfo = order.addrinfo;
            this.addrcount = order.addrcount;
            this.isfromcart = order.isfromcart;
            this.addrid =
              order.addrinfo && order.addrinfo.ID ? order.addrinfo.ID : 0;

            this.getShippingFee();
          } else {
            showToast({
              message:
                code == "NOTLOGIN"
                  ? this.SYSLANG[msg]
                  : code == "ERROR" || code == "SUCCESS"
                  ? this.PAGELANG[msg]
                  : msg,
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                } else {
                  this.$router.back();
                }
              },
            });
          }
        });
		*/
    },
	
	//用户选择地址返回订单页面
	initTwo() {
		console.log('this.addressId',this.addressId)
		console.log('this.isFirst',this.isFirst)
		if(this.isFirst==1){
			return
		}
		this.axios
		  .post(this.actions.orderCalc, {
		           skus:this.skus,
				   addressId:this.addressId,
				   deductionSkus:this.deductionSkus,
				   luckCharmOrderStatus:this.luckCharmOrderStatus,
				   type:"take",
				   resultant:this.resultant
		  })
		  .then((response) => {
		    closeToast();
		    let { code, message } = response.data;
		    if (code == "200") {
				 console.log('response',response)
			     //this.addrinfo=response.data.data.addressModel
				   this.ShippingFee=response.data.data.totalFee/100
				   this.ShippingList=response.data.data.skus[0].list
				   this.TotalMoney=(response.data.data.totalProductMoney/100+response.data.data.totalFee/100)
				   this.isFirst=1
				   this.isGiveStickers=response.data.data.isGiveStickers
				   this.getShippingFee()
				  //  if(this.deductionSkus.length>0){
						//  let money=0//赠品金额
						//  let number=0 //赠品数量 (10的倍数)
						//  this.skus.map((item)=>{
						// 	 number=number+(item.cartQuantity/10)
						//  })
						//  this.discountList=response.data.data.memberBrandCodeModels
						//  this.discountList.map((item,index)=>{
						// 	       if(index<number){
						// 			  money=money+(item.deductibleAmount/100) 
						// 			  this.selectCoupon.push(item)
						// 		   }
					  
						//  })
						// this.TotalMoney=this.TotalMoney-money   
				  //  }
				   
				   
		    } else {
		      showToast(
		        code != 200 ? message : message
		      );
		    }
		})
	},
	
    // 计算运费
    getShippingFee() {
      if (this.addrinfo && this.addrinfo.addressId) {
		  this.axios.post(this.actions.calcAvailabeTemplate,{addressId:this.addrinfo.addressId,skus:this.skus,type:"take"})
		            .then((res)=>{
		  			     console.log('res',res)
						  //this.countryList=res.data.data
						   this.ShippingName=res.data.data[0].name
						   // res.data.data.shift()
						   this.countryList =res.data.data;
						   this.shipmentId=res.data.data[0].shipmentId
						 //this.ShippingFee = (shippingfee * 1).toFixed(2)
		  		  })
		  
      }
    },

	
	
    // 提交订单
    onSubmit() {
      if (!this.addrinfo || !this.addrinfo.addressId) {
        showToast(this.PAGELANG.notaddrss);
        return;
      }
	  if(this.orderCode!=''){
		 this.getPay(this.orderCode)
		 return
	  }else{
		this.order.addressId = this.addrinfo.addressId; //地址id
		
		// let objSku=[]
		// this.skus.map(item=>{
		// 	 let a={}
		// 	 a.isOpenToBooking=item.isOpenToBooking,
		// 	 a.isTaiWanStork=0
		// 	 a.openToBookingDate=item.openToBookingDate
		// 	 a.productId=item.productId
		// 	 a.quantity=item.quantity
		// 	 a.skuId=item.skuId	
		// 	 objSku.push(a)
		// })
		
		this.order.skus = this.skus; //商品
		console.log('this.deductionSkus',this.deductionSkus)
	
		this.order.deductionSkus =this.deductionSkus;//赠品（使用优惠劵则发送memberBrandCodeModels）
		this.order.memberBrandCodeModels=this.selectCoupon,
		this.order.orderFrom=6,//固定为6
		this.order.shipmentId=this.shipmentId,//运费模板
		this.order.type="take";//固定类型
		this.order.resultant=this.resultant;//1688倍數
		if(this.isShowGiveStickers==1){
				 this.order.isGiveStickers=1 
		}else{
				   this.order.isGiveStickers=0
		}//是否用团贴纸
		this.order.luckCharmOrderStatus=this.luckCharmOrderStatus,//是否用混选
		console.log('order', this.order)
		this.axios
		  .post(this.actions.orderAddorder,this.order)
		  .then((res) => {
		    //closeToast();
		    let { code, message} = res.data;
				  console.log('code',code)
				  if(code==200){
						  let orderCode=res.data.data.orderCode;
						  this.orderCode=orderCode
						  console.log(orderCode)
						  this.getPay(orderCode)
						  
						  return;
				  }
				  if(code!=200){
					showToast({
					  message:message,
					  forbidClick: true,
					  onClose: () => {
					  },
					});  
				  }
		  })  
	  }

    },
	getPay(orderCode){
		this.showOverlay=true
		this.orderCode=orderCode
	},
	isshowOverlay(){
		this.axios
		  .post(this.actions.getPayments,{orderCode:this.orderCode,payType:this.payType}).then((res)=>{
			   if(res.data.code==200){
				   console.log(res.data.data.payConfig.tradeNo)
				   this.tradeNo=res.data.data.payConfig.tradeNo
				   this.axios
				     .post(this.actions.validatePassword,{password:md5(this.password)}).then((res)=>{
				   	   if(res.data.code==200){			    
				   			this.axios
				   			  .post(this.actions.getPay,{token:res.data.data.token,tradeNo:this.tradeNo}).then((res)=>{
				   				     console.log('res.data.code',res.data.code)
				   				   if(res.data.code==200){
				   					  showToast({
				   						  message:res.data.message,
				   						  forbidClick: true,
				   						  duration:1000,
				   						  onClose: () => {
				   						            for (let key in history.state) {
				   						              delete history.state[key];
				   						            }
				   						            this.$router.replace({
				   						              path: "/order/detail",
				   						              query: {
				   						                orderid:this.orderCode,
				   						                isfrom: "placeorder",
				   						              },
				   						            });
				   						            this.showOverlay=false
				   						        },
				   						  
				   					  }) 
				   				   }else{
				   					   showToast({
				   					     message:res.data.message,
				   					     forbidClick: true,
				   					     onClose: () => {
				   					     },
				   					   });
				   				   }
				   			  })
				   		    
				   	   }else{
				   		   showToast({
				   		     message:res.data.message,
				   		     forbidClick: true,
				   		     onClose: () => {
				   		     },
				   		   });
				   	   }
				   	  
				     })
				   
				   
			   }else{
				   showToast({
				     message:res.data.message,
				     forbidClick: true,
				     onClose: () => {
				     },
				   });
			   }
			  
	    })
	},
	closeshowOverlay(){
		 this.showOverlay=false
		// this.$router.replace({
		//   path: "/order/detail",
		//   query: {
		//     orderid:this.orderCode,
		//     isfrom: "placeorder",
		//   },
		// });
	},
    // 读取運費模板
    getAllAddress() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });
      this.axios.get(this.actions.calcAvailabeTemplate).then(({ data }) => {
        closeToast();
        let { code, msg, list } = data;
        if (code == "SUCCESS") {
          this.addressList = list;
          console.log(this.addrinfo);
          if (list.length > 0 && (!this.addrinfo || !this.addrinfo.ID)) {
            this.addrinfo = list[0];
            this.addrid = list[0].ID;
          }
        } else {
          showToast({
            message: this.SYSLANG[msg],
            forbidClick: true,
            onClose: () => {
              if (code == "NOTLOGIN") {
                this.$router.replace({
                  path: "/login",
                });
              }
            },
          });
        }
      });
    },
    setDefault(val) {
      console.log(val);
      // 设置默认地址
      this.axios.get(this.actions.address_setdefault + val).then(({ data }) => {
        let { code, msg } = data;
        console.log(code, msg);
        if (code == "SUCCESS") {
          for (let i = 0; i < this.addressList.length; i++) {
            if (this.addressList[i].ID == val) {
              this.addressList[i].IsDefault = 1;
            } else {
              this.addressList[i].IsDefault = 0;
            }
          }
        } else {
          showToast(
            code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg]
          );
        }
      });
    },
    editAddress(addrid = 0) {
      this.addressid = addrid;
    },
    changeAddress(val) {
      this.addrinfo.ID = val;
    },
  },
};
</script>


<style src="../assets/css/order.css" scoped></style>
<style src="../assets/css/cartpc.css"></style>
<style scoped>
@media (max-width: 678px) {
	
	.custom-input {
	  background-color: #f0f0f0;
	  border: 1px solid #ccc;
	  border-radius: 4px;
	  padding: 8px;
	  font-size: 16px;
	}
  .pc-order-pre {
    display: none;
  }
  .goods-money-pay-content {
    width: 320px;
    background: #fff;
    border-radius:10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 25px 50px 14px;
    box-sizing: border-box;
	text-align: center;
  }
  
  
  .order-page {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 16px 0 16px;
    min-height: 100vh;
    background-color: #f7f7f9;
  }

  .order-page >>> .van-submit-bar__text {
    text-align: left;
  }
  .order-page .content {
    padding: 3vw;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 2vw;
  }

  .order-page .content.address {
    display: flex;
    align-items: center;
    background: #fff url(../assets/images/address_bg.png) repeat-x 0 100%;
    background-size: auto 4px;
  }
  .order-page .content.address::after {
    content: "\e68b";
    font-family: "iconfont";
    font-size: 18px;
    margin-left: 10px;
    color: #000;
  }

  .order-page .content.address .info {
    display: flex;
    flex-direction: column;
    width: 0;
    flex-grow: 1;
    line-height: 24px;
    font-size: 14px;
    color: #000;
    padding: 4px 0;
  }
  .order-page .content.address .info .area {
    color: #7c7c7c;
  }

  .order-page .content.address .info .detail {
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
  }

  .order-page .content .big-title {
    line-height: 36px;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }

  .order-page .content.shopping-list .shopping-item {
    display: flex;
    height: 12vw;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #000;
  }
  .order-page .content.shopping-list .shopping-item .title {
    width: 120px;
  }
  .order-page .content.shopping-list .shopping-item .value {
    width: 0;
    flex-grow: 1;
    text-align: right;
    --van-cell-vertical-padding: 0;
    --van-cell-horizontal-padding: 0;
    --van-field-icon-size: 14px;
  }

  .remark-popup {
    padding: 0 16px 16px 16px;
  }
  .remark-popup .header {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #666;
  }

  .remark-popup .header .submit {
    color: #fe295f;
  }
  .remark-popup .header .title {
    font-size: 16px;
    font-weight: bold;
    color: #000;
  }

  .remark-popup .remark-input {
    --van-cell-vertical-padding: 0;
    --van-cell-horizontal-padding: 0;
  }

  .remark-popup .remark-input >>> .van-field__control {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 5px 10px;
  }

  >>> .van-submit-bar__price-integer {
    font-size: 0;
  }
  >>> .van-submit-bar__price-integer::before {
    content: var(--aog-totalprice);
    font-size: var(--van-submit-bar-price-integer-font-size);
    font-family: var(--van-submit-bar-price-font);
  }
}
</style>





